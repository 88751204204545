<div class="card-content" (click)="navigateToPool()">
  <div class="d-flex align-items-center justify-content-between">
    <div class="token-name token-name-inline">
      <div class="token-name__img">
        <img src="../../assets/images/pool-thumb.png"/>
      </div>
      <div class="token-name__info">
        <div class="token-title">{{item.name}}</div>
      </div>
    </div>

     <button mat-icon-button
        (click)="navigateToPoolEdit()"
        matTooltip="Edit Pool"
        matTooltipPosition="above">
      <span class="icomoon-edit"></span>
    </button>
  </div>

  <div class="pool-info mt-4">
    <div class="pool-info__row d-flex">
      <div class="label me-auto text-muted">APY</div>
      <div class="value-sm">{{apy| number: '1.0-2'}}%</div>
    </div>
    <div class="pool-info__row d-flex">
      <div class="label me-auto text-muted">Staking Token</div>
      <div class="value-sm">{{item.stakingTokenSymbol}}</div>
    </div>
    <div class="pool-info__row d-flex">
      <div class="label me-auto text-muted">Reward Token</div>
      <div class="value-sm">{{item.poolTokenSymbol}}</div>
    </div>
    <div class="pool-info__row d-flex">
      <div class="label me-auto text-muted">Deposit Fee</div>
      <div class="value-sm">{{item.depositFeeBP}}%</div>
    </div>
    <div class="pool-info__row d-flex">
      <div class="label me-auto text-muted">Your stake</div>
      <div class="value-sm">{{currentStakeAmount| shiftDecimals:-item.poolTokenDecimals| number: '1.0-2'}} {{item.stakingTokenSymbol}}</div>
    </div>

    <!--TODO: Calculate penalty -->
    <div class="pool-info__row d-flex">
      <div class="label me-auto text-muted">Your Reward</div>
      <div class="value-sm">0 {{item.poolTokenSymbol}}</div>
    </div>
    <div class="pool-info__row d-flex">
      <div class="label me-auto text-muted">Your Penalty</div>
      <div class="value-sm">0 %</div>
    </div>
  </div>

  <div class="pool-progress mt-4">
    <div class="progress progress-xs">
      <div class="progress-bar" role="progressbar" [style.width.%]="getProgressPercent">
      </div>
    </div>
    <div class="progress-legend d-flex justify-content-between">
      <div class="progress-legend__percent">{{getProgressPercent| number: '1.0-2'}}%</div>
      <div class="progress-legend__value">{{getCurrentPoolTokenAmount| number: '1.0-2'}} / {{item.poolTokenAmount| shiftDecimals:-item.poolTokenDecimals| number: '1.0-2'}}</div>
    </div>
  </div>
</div>

