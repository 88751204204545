<header class="dashboard-header">
  <div class="dashboard-header__title">
    <h1 class="header-title" [ngSwitch]="routerPath">
      <span *ngSwitchCase="'/login'">Login</span>
      <span *ngSwitchCase="'/users'"><i class="icomoon-users"></i> Users</span>
      <span *ngSwitchCase="'/wallet-approval'"><i class="icomoon-address-book"></i> Wallet Approvals</span>
      <a href="/wallet-approval" class="back-to-prev" *ngSwitchCase="'/user-details'">User Details</a>
      <span *ngSwitchCase="'/admins'"><i class="icomoon-users"></i> Admins</span>
      <span *ngSwitchCase="'/penalties'"><i class="icomoon-chart-down"></i> Penalties</span>
      <span *ngSwitchCase="'/tiers'"><i class="icomoon-tiers"></i>Tiers</span>
      <span *ngSwitchCase="'/settings'"><i class="icomoon-warning"></i> Settings</span>

      <span *ngSwitchCase="'/staking'">Staking pool</span>
      <span *ngSwitchCase="'/farming'">Farming pool</span>
      <span class="back-to-prev" *ngSwitchCase="'/edit-pool'" (click)="backToPrev()">Edit Pool</span>
      <span class="back-to-prev" *ngSwitchCase="'/pool-detail'" (click)="backToPrev()">Pool Details</span>
      <span *ngSwitchCase="'/create-pool'">Create Pool</span>

      <span *ngSwitchCase="'/claiming'"><i class="icomoon-claim"></i> Claiming Deals</span>
      <span *ngSwitchCase="'/create-claiming-deal'">Create Claiming Deal</span>
      <span class="back-to-prev" *ngSwitchCase="'/edit-claiming-deal'" (click)="backToPrev()">Edit Claiming Deal</span>
      <span class="back-to-prev" *ngSwitchCase="'/claiming-deal-detail'" (click)="backToPrev()">Deal Details</span>

      <span *ngSwitchCase="'/deals'">Deals</span>
      <span *ngSwitchCase="'/create-deal'">Create Deal</span>
      <span *ngSwitchCase="'/create-wallet-deal'">Create Empty Deal</span>
      <span class="back-to-prev" *ngSwitchCase="'/edit-deal'" (click)="backToPrev()">Edit Deal</span>
      <span class="back-to-prev" *ngSwitchCase="'/deal-detail'" (click)="backToPrev()">Deal Details</span>
    </h1>
  </div>

  <app-wallet></app-wallet>

  <div class="dashboard-header__select">
    <button mat-button class="dropdown-button __select-network" [matMenuTriggerFor]="networks">
      <img src="{{ web3Service.chainIdNumber | imgNetworkPipe }}"/>
      {{getNetworkName(web3Service.chainIdNumber)}}
    </button>
    <mat-menu #networks="matMenu" class="select-network-menu">
      <button  mat-menu-item *ngFor="let networkId of availableNetworks" (click)="selectNetworkClick(networkId)">
        <img src="{{ networkId | imgNetworkPipe }}"/>
        {{getNetworkName(networkId)}}
      </button>
    </mat-menu>
  </div>
</header>
