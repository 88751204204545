import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StakingPoolDTO, StakingPoolServiceProxy, StakingPoolUpdateDTO, StakingPoolWhitelistUpdateDTO } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';
import { EventBus } from 'src/shared/event-bus';
import { NetworkNamePipe } from 'src/shared/pipes/networkName.pipe';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { Web3Service } from 'src/shared/web3-service';

@Component({
  templateUrl: './edit-pool.component.html',
  styleUrls: ['./edit-pool.component.scss']
})
export class EditPoolComponent extends ComponentBase implements OnInit {

  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private stakingPoolService: StakingPoolServiceProxy,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.originStringWhitelist = "";
   }

  waiting: boolean;
  item: StakingPoolDTO;
  poolAddress: string;
  account: string;

  poolName: string;
  poolDescription: string;
  startTime: number;
  finishTime: number;
  hasWhiteListing: boolean;
  poolTokenAmount: string;
  depositFeeBP: number;
  feeTo: string;
  visibleForUsers: boolean;
  isApproved: boolean;
  isPublic: boolean;
  stringWhitelist: string;
  originStringWhitelist: string;

  feeAmount: number;
  feeToken: string;

  stakingToken: string;
  poolToken: string;

  async ngOnInit(): Promise<void> {
    await this.web3Service.initWeb3();

    this.route
      .queryParams
      .subscribe(params => {
        this.poolAddress = params['address'];
        console.log(`staking pool address: ${this.poolAddress}`);
        this.getStakingPoolDTO();
        this.getFeeAmount();
      });

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    //this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    //});

    //this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    //});
    this.account = this.userSessionProvider.linkedWallet;
  }

  async getStakingPoolDTO(): Promise<void> {
    this.stakingPoolService.getByAddress(this.poolAddress).subscribe(result => {
      this.item = result;
      this.depositFeeBP = result.depositFeeBP;
      this.startTime = result.startTime;
      this.finishTime = result.finishTime;
      this.hasWhiteListing = result.hasWhitelisting;
      this.stakingToken = result.stakingToken;
      this.poolToken = result.poolToken
      this.poolTokenAmount = (parseInt(result.poolTokenAmount) / (10 ** 18)).toString();
      this.poolName = result.name;
      this.poolDescription = result.description;
      this.visibleForUsers = result.visibleForUsers;
      this.isApproved = result.isApproved;
      this.isPublic = result.isPublic;
      this.stringWhitelist = result.whitelistingAddresses;
      this.originStringWhitelist = result.whitelistingAddresses;

      console.log(this.item);
      // if (this.web3Service.chainIdNumber != this.item.chainId) {
      //   let chainName = new NetworkNamePipe().transform(this.item.chainId);
      //   this.showErrorModal(`Select ${chainName} network in metamask!`);
      //   this.router.navigate(["/staking"]);
      // }
    },
      error => {
        this.processServiceError(error);
      });
  }

  async updateStakingPoolDb(): Promise<void> {
    this.waiting = true;

    var data: StakingPoolUpdateDTO = new StakingPoolUpdateDTO();
    data.poolAddress = this.poolAddress;
    data.name = this.poolName;
    data.description = this.poolDescription;
    data.visibleForUsers = this.visibleForUsers;
    data.isApproved = this.isApproved;
    data.isPublic = this.isPublic;

    console.log(data);

    this.waiting = true;

    this.stakingPoolService.update(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.getStakingPoolDTO();
          this.showInfoModal("Saved")
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateWhitelistClick(): Promise<void> {
    this.waiting = true;
    // interact with contract:
    this.updateWhitelistContract();
    var data: StakingPoolWhitelistUpdateDTO = new StakingPoolWhitelistUpdateDTO();
    data.poolAddress = this.poolAddress;
    data.hasWhitelisting = this.hasWhiteListing;
    data.addresses = this.stringWhitelist;

    console.log(data);

    this.waiting = true;

    this.stakingPoolService.updateWhiteList(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.getStakingPoolDTO();
          this.showInfoModal("Saved")
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateWhitelistContract() {
    let oldAddresses = this.originStringWhitelist.split(',');
    let newAddresses = this.stringWhitelist.trim().split(',');
    let addressesToAdd = newAddresses.filter(p => !(oldAddresses.includes(p)));
    let addressesToRemove = oldAddresses.filter(p => !newAddresses.includes(p));
    console.log("addressesToAdd");
    console.log(addressesToAdd);
    console.log("addressesToRemove");
    console.log(addressesToRemove);
    if(addressesToAdd.length > 0 && addressesToAdd[0] !="") {
      const contractEventsSource = this.web3Service.addAddressesToWhitelist(this.account, this.item.poolAddress, addressesToAdd);
      
      try {
        let response = await contractEventsSource.receipt$.toPromise();
        console.log(response);
        this.showInfoModal('Confirmed transaction');
      } catch (err) {
        console.info('catch');
        console.info(err);
      }
    }
    if(addressesToRemove.length > 0 && addressesToRemove[0] !="") {
      const contractEventsSource = this.web3Service.removeAddressesToWhitelist(this.account, this.item.poolAddress, addressesToRemove);
      
      try {
        let response = await contractEventsSource.receipt$.toPromise();
        console.log(response);
        this.showInfoModal('Confirmed transaction');
      } catch (err) {
        console.info('catch');
        console.info(err);
      }
    }
    // console.log(oldAddresses);
    // console.log(newAddresses);

  }

  onApprovedChanged(approved: boolean): void {
    this.isApproved = approved;
    this.isPublic = !approved;
  }

  async getFeeAmount(): Promise<void> {
    this.feeAmount = (await this.web3Service.GetStakeMasterFeeAmount()) / (10 ** 18);
    console.log(this.feeAmount);
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = "";
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
