import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { menu, MenuItem } from './menu';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
  styleUrls: ['./app-aside.component.scss']
})
export class AppAsideComponent implements OnInit {
  constructor(private router: Router, private userSessionProvider: UserSessionProvider,) {}
  public menuItems: MenuItem[];
  public routerPath: string;
  ngOnInit(): void {
    const chainId = this.userSessionProvider.getChainId();
    this.menuItems = menu.filter(i=>  (i.supportedChains.includes(chainId) || i.supportedChains.length == 0) && !i.disabled);
    this.router.events.subscribe(value => {
      this.routerPath = this.router.url.split('?')[0];
    });

    const elementsArray = document.querySelectorAll('.sidenav__nav a.nav-link');

    elementsArray.forEach(function(elem) {
      elem.addEventListener('click', function() {
        document.body.classList.remove('mob-menu-active');
      });
    });
  }
  toggleSubMenu(event: any){
    const item = event.target.nextSibling;
    if (item.classList.contains('show')) {
      item.classList.remove('show');
    } else {
      item.classList.add('show');
    }
  }
}
