import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { UserDTO, UsersServiceProxy } from '../../service-proxies/service-proxies';
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends ComponentBase implements OnInit {
  public users: Array<UserDTO> = [];
  waiting: boolean = false;
  account: string = '';
  constructor(
    private userSessionProvider: UserSessionProvider,
    private usersServiceProxy: UsersServiceProxy,
    private router: Router
  ) {
    super();
  }
  //https://test-api.sumsub.com/checkus#/applicant/60bce8ed8a9a0400018c7f74/basicInfo?clientId=bullperks
  searchString: string;
  public getSumSupURL(applicantId: string): string {
    return `${environment.sumsupApplicantUrl}${applicantId}/basicInfo?clientId=${environment.sumsupClientId}`;
  }
  async ngOnInit() {
    this.usersServiceProxy.getAll()
      .subscribe(result => {
        this.users = result;
      },
        error => {
          this.processServiceError(error);
        });
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this.showErrorModal(JSON.parse(error.response).message);
  }

  searchClick() {
    if (this.searchString) {
      this.usersServiceProxy.searchUsers(this.searchString)
        .subscribe(result => {
          this.users = result;
        },
          error => {
            this.processServiceError(error);
          });
    }
    else {
      this.usersServiceProxy.getAll()
        .subscribe(result => {
          this.users = result;
        },
          error => {
            this.processServiceError(error);
          });
    }
  }
  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
  sortData(sort: Sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.users = data;
      return;
    }

    this.users = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return compare(a.creationDateTime, b.creationDateTime, isAsc);
        case 'email': return compare(a.email, b.email, isAsc);
        default: return 0;
      }
    });
  }

  public navigateToUserDetails(userAddress: string) {
    this.router.navigate(['/user-details'], { queryParams: { userid: userAddress } });
  }
}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
