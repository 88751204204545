import {Component, OnInit} from '@angular/core';
import {ComponentBase} from '../../shared/component-base';
import {EventBus} from '../../shared/event-bus';
import {Web3Service} from '../../shared/web3-service';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {
  ClaimingDealServiceProxy,
  DealDTO, DealEventsCollectProcessStatus,
  DealServiceProxy,
  DealUpdateDTO,
  DealWhiteListUpdateDTO, ReloadDealEventsStatusDTO
} from '../../service-proxies/service-proxies';
import {ActivatedRoute, Router} from '@angular/router';
import {NetworkNamePipe} from '../../shared/pipes/networkName.pipe';
import BigNumber from 'bignumber.js';
import {environment} from '../../environments/environment';

@Component({
  selector: 'edit-deal',
  templateUrl: './edit-deal.component.html',
  styleUrls: ['./edit-deal.component.scss'],
})
export class EditDealComponent extends ComponentBase implements OnInit {
  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: DealServiceProxy,
    private claimingDealServiceProxy: ClaimingDealServiceProxy,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  waiting = false;
  account = '';

  isCollectWalletDeal = false;
  dealAddress: string;
  deal: DealDTO;
  name: string;
  description: string;
  emissionDescription: string;
  projectURL: string;
  logoURL: string;
  dealType: number;
  targetNetwork: number;
  visibleForUsers: boolean;
  enabledWhiteList: boolean;
  whiteListAddresses: string;

  paymentToken: string;

  tokenPrice: string;
  rewardToken: string;
  rewardDecimals: number;
  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;
  maxDistributedTokenAmount: string;
  maxDistributedTokenAmountInput: string;
  totalRaise: string; // Sum of all payments (in payment token)
  tokensForDistribution: string;
  minimumRaise: string;
  distributedTokens: string;
  allowRefund: boolean;

  vestingContractAddress: string;
  vestingPercent: number;
  vestingStart: number;
  vestingInterval: number;
  vestingDuration: number;

  // #end region contract data
  paymentDecimal: number;
  paymentTokenSymbol: string;
  rewardTokenSymbol: string;
  rewardTokenName: string;
  tokensPerETH: number;

  allTiers: any[] = [];
  allMainTiers: any[] = [];

  newTierBlpAmount: number;
  newTierTicketSize: number;
  newTierAllocation: number;

  updateTierIndex = -1;
  updateTierBlpAmount: number;
  updateTierTicketSize: number;
  updateTierAllocation: number;

  socialTwitter: string;
  socialMedium: string;
  socialYouTube: string;
  socialFacebook: string;
  socialTelegram: string;
  socialLinkedIn: string;
  socialInstagram: string;

  updateTimeTimerId: NodeJS.Timeout;
  now: number;
  dealTypes = [
    { id: 1, label: 'IDO' },
    { id: 0, label: 'VC' },
    { id: 2, label: 'INO' }
  ];


  networkTypes: any = environment.bsc.networkTypes;
  dealEvents: string;
  eventExtractionsStatus: string;
  eventExtractionsStatusDto: ReloadDealEventsStatusDTO;

  async ngOnInit() {
    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

    }, 1000);

    await this.web3Service.initWeb3();

    this.route
      .queryParams
      .subscribe(params => {
        this.dealAddress = params.address;
        console.log(`deal address: ${this.dealAddress}`);
        this.getDealDTO();
      });

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    // this.eventBus.outputNetworkChanged.subscribe(result => {
    //  console.log('outputNetworkChanged subscription:' + result);
    //  this.setToNetworkAndPairTo(result);
    // });

    // this.eventBus.fromPairChanged.subscribe(result => {
    //  console.log('fromPairChanged subscription:' + result);
    //  this.setPairFrom(result);
    // });
    this.account = this.userSessionProvider.linkedWallet;
    this.updateDealContractData();
  }


  getDealDTO() {
    this.dealService.getByAddress(this.dealAddress).subscribe(result => {
      this.deal = result;
      this.rewardTokenSymbol = this.deal.rewardTokenSymbol;
      this.rewardToken = this.deal.rewardToken;
      this.isCollectWalletDeal = this.deal.isDealWithoutDistribution;
      this.name = this.deal.name;
      this.description = this.deal.description;
      this.emissionDescription = this.deal.emissionDescription;
      this.projectURL = this.deal.url;
      this.logoURL = this.deal.logoURL;
      this.dealType = this.deal.dealType;
      this.visibleForUsers = this.deal.visibleForUsers;

      this.enabledWhiteList = this.deal.enabledWhitelisting;
      this.whiteListAddresses = this.deal.whitelistingAddresses;

      this.socialMedium = this.deal.mediumURL;
      this.socialYouTube = this.deal.youTubeURL;
      this.socialFacebook = this.deal.facebookURL;
      this.socialTelegram = this.deal.telegramURL;
      this.socialLinkedIn = this.deal.linkedInURL;
      this.socialInstagram = this.deal.instagramURL;
      this.targetNetwork = this.deal.targetNetwork;

      console.log(this.deal);
      if (this.web3Service.chainIdNumber != this.deal.chainId) {
        const chainName = new NetworkNamePipe().transform(this.deal.chainId);
        this.showErrorModal(`Select ${chainName} network in metamask!`);
        this.router.navigate(['/deals']);
      }
    },
      error => {
        this.processServiceError(error);
      });
    // this.showEventsExtractionStatus();
  }

  eventLogin(username: string): void {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
  }

  eventLogout(): void {
    this.account = '';
    // this.balance = null;
    // console.log('eventLogout')
    // if (this.timerId) {
    //  console.log('clearInterval');
    //  clearInterval(this.timerId);
    // }
  }


  async updateDealContractData() {

    console.log(`Get contract data ${this.dealAddress}`);
    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != '0x0000000000000000000000000000000000000000') {
      this.web3Service.GetContractSymbol(this.paymentToken).then((resp) => {
        this.paymentTokenSymbol = resp;
      });
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    }
    else {
      // this.paymentTokenSymbol = get from DTO
      this.paymentDecimal = 18;
    }
    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);

    console.log(`isCollectWalletDeal: ${this.isCollectWalletDeal}`);
    // TODO: if another chain isCollectWalletDeal = true
    if (!this.isCollectWalletDeal) {
      this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress);
      this.web3Service.GetContractSymbol(this.rewardToken).then((resp) => {
        this.rewardTokenSymbol = resp;
      });
      this.web3Service.GetContractName(this.rewardToken).then((resp) => {
        this.rewardTokenName = resp;
      });
    }

    if (this.isCollectWalletDeal) {
      this.rewardDecimals = 18;
    }
    else {
      this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    }
    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    this.startTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
    this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));

    this.web3Service.getDealMaxDistributedTokenAmount(this.dealAddress).then((resp) => {
      this.maxDistributedTokenAmount = resp;
      this.maxDistributedTokenAmountInput = new BigNumber(this.maxDistributedTokenAmount).shiftedBy(this.rewardDecimals).toString();
    });

    this.web3Service.getDealMinimumRaise(this.dealAddress).then((resp) => {
      this.minimumRaise = resp;
    });

    this.web3Service.getDealAllowRefund(this.dealAddress).then((resp) => {
      this.allowRefund = Boolean(resp);
    });


    this.allTiers = [];
    const tiersLength = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
    for (let i = 0; i < tiersLength; i++) {
      const tier = await this.web3Service.getDealTiers(this.dealAddress, i);
      console.log(tier);
      this.allTiers.push(tier);
    }
    this.updateMainTiersContractData();

    this.web3Service.getVestingPercent(this.dealAddress).then((resp) => {
      this.vestingPercent = parseInt(resp);
    });

    const vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == '0x0000000000000000000000000000000000000000' ? null : vestAddress;

    if (this.vestingContractAddress) {

      this.web3Service.getVVestingStart(this.vestingContractAddress).then((resp) => {
        this.vestingStart = parseInt(resp);
      });

      this.web3Service.getVVestingInterval(this.vestingContractAddress).then((resp) => {
        this.vestingInterval = parseInt(resp);
      });

      this.web3Service.getVVestingDuration(this.vestingContractAddress).then((resp) => {
        this.vestingDuration = parseInt(resp);
      });
    }
  }

  async updateMainTiersContractData() {
    console.log('updateMainTiersContractData');

    this.allMainTiers = [];
    const tiersLength = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiersLength; i++) {
      const tier = await this.web3Service.getDealLockupsTiers(i);
      console.log(tier);
      this.allMainTiers.push(tier);
    }
    if (this.allMainTiers.length > this.allTiers.length) {
      console.log('newTierBlpAmount');
      this.newTierBlpAmount = this.toNumberFromWei(this.allMainTiers[this.allTiers.length].blpAmount, 18);
    }

  }

  async saveNewTimeClick(): Promise<void> {
    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealUpdateTime(this.dealAddress, this.account, this.startTimestamp, this.finishTimestamp, this.startClaimTimestamp);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      // dialogRef.close();
      // this._alertSrv.show('Confirmed transaction');
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();

    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async syncTimeDb(): Promise<void> {
    this.waiting = true;

    this.waiting = true;

    this.dealService.syncTimestamp(this.dealAddress)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
          this.getDealDTO();
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateDealDb(): Promise<void> {
    this.waiting = true;

    const data: DealUpdateDTO = new DealUpdateDTO();
    data.dealAddress = this.dealAddress;
    data.name = this.name;
    data.description = this.description;
    data.emissionDescription = this.emissionDescription;
    data.url = this.projectURL;
    data.logoURL = this.logoURL;
    data.dealType = this.dealType;
    data.visibleForUsers = this.visibleForUsers;
    data.mediumURL = this.socialMedium;
    data.youTubeURL = this.socialYouTube;
    data.facebookURL = this.socialFacebook;
    data.telegramURL = this.socialTelegram;
    data.linkedInURL = this.socialLinkedIn;
    data.instagramURL = this.socialInstagram;
    data.targetNetwork = this.targetNetwork;

    console.log({data});

    this.dealService.update(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async UpdateRewardTokenSymbol(): Promise<void> {
    this.waiting = true;

    this.dealService.updateRewardTokenSymbol(this.dealAddress, this.rewardTokenSymbol)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async updateWhitelist(): Promise<void> {
    this.waiting = true;

    const data: DealWhiteListUpdateDTO = new DealWhiteListUpdateDTO();
    data.dealAddress = this.dealAddress;
    data.enabledWhiteList = this.enabledWhiteList;
    data.addresses = this.whiteListAddresses;

    this.waiting = true;

    this.dealService.updateWhiteList(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async createVestingClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealCreateVestingContract(
      this.account, this.dealAddress, this.vestingPercent, this.vestingStart, this.vestingInterval, this.vestingDuration);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async saveVestingPercentClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealUpdateVestingPercent(this.account, this.dealAddress, this.vestingPercent);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async updateVestingClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.vestingUpdateVestingTime(
      this.account, this.vestingContractAddress, this.vestingStart, this.vestingInterval, this.vestingDuration);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  select(index: number) {
    this.updateTierIndex = index;
    this.updateTierBlpAmount = this.toNumberFromWei(this.allTiers[index].blpAmount, 18);
    this.updateTierTicketSize = this.toNumberFromWei(this.allTiers[index].ticketSize, this.deal?.paymentDecimal);
    this.updateTierAllocation = this.toNumberFromWei(this.allTiers[index].allocation, this.deal?.rewardDecimal);
  }

  async updateTierClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealUpdateTier(this.account, this.dealAddress, this.updateTierIndex,
      this.updateTierBlpAmount, this.updateTierTicketSize, this.updateTierAllocation, this.deal.paymentDecimal, this.deal.rewardDecimal);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateTierIndex = -1;
      this.updateTierBlpAmount = null;
      this.updateTierTicketSize = null;
      this.updateTierAllocation = null;
      this.updateDealContractData();
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async addTierClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealAddTier(
      this.account, this.dealAddress, this.newTierBlpAmount, this.newTierTicketSize, this.newTierAllocation,
      this.deal.paymentDecimal, this.deal.rewardDecimal);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierBlpAmount = null;
      this.newTierTicketSize = null;
      this.newTierAllocation = null;
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async withdrawFundsClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealWithdrawFunds(this.account, this.dealAddress);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierBlpAmount = null;
      this.newTierTicketSize = null;
      this.newTierAllocation = null;
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  async withdrawNotSoldTokensClick(): Promise<void> {

    this.waiting = true;

    // const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealWithdrawNotSoldTokens(this.account, this.dealAddress);

    // contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();
      // dialogRef.close();
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();
      this.newTierBlpAmount = null;
      this.newTierTicketSize = null;
      this.newTierAllocation = null;
    } catch (err) {
      // dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  async extractDealPaymentEventsClick(): Promise<void>  {

    this.dealService.collectDealEvents(this.dealAddress)
      .subscribe(
        result => {
          this.showInfoModal('Events Collected');
        },
        error => {
          this.processServiceError(error);
        }
      );
  }

  async updateRewardAddress(): Promise<void> {
    this.waiting = true;

    this.dealService.updateRewardTokenAddress(this.dealAddress, this.rewardToken)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  async saveMaxDistributedTokenAmountClick(): Promise<void> {
    this.waiting = true;

    const stringMaxDistributedTokenAmount = '0x' + new BigNumber(this.maxDistributedTokenAmount).shiftedBy(this.rewardDecimals).toString(16);
    const contractEventsSource = this.web3Service.dealUpdateMaxDistributedTokenAmount(this.dealAddress, this.account, stringMaxDistributedTokenAmount);

    try {
      const response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this.showInfoModal('Confirmed transaction');
      this.updateDealContractData();

    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async syncMaxDistributedTokenAmountDb(): Promise<void> {
    this.waiting = true;

    this.dealService.updateMaxDistributedTokenAmount(this.dealAddress, this.maxDistributedTokenAmount)
      .subscribe(
        result => {
          this.waiting = false;
          this.showInfoModal('Saved');
        },
        error => {
          this.waiting = false;
          this.processServiceError(error);
        }
      );
  }

  showDealEventsClick() {
    this.dealService.dealEventsToCsv(this.dealAddress).subscribe(result => {
      this.dealEvents = result;
    },
      error => {
        this.processServiceError(error);
      });
  }

  // showEventsExtractionStatus() {
  //   this.dealService.getReloadDealEventsStatus(this.dealAddress).subscribe(result => {
  //     this.eventExtractionsStatusDto = result;
  //     this.eventExtractionsStatus = 'Status =' + DealEventsCollectProcessStatus[result.status].toString() +
  //       '\n% of completion = ' + result.completionPercent +
  //       '\n Start time = ' + result.startTimestamp +
  //       '\n End time = ' + result.endTimestamp;
  //    },
  //     error => {
  //       console.log("MISTAKE 101010101010");
  //       this.processServiceError(error);
  //     });
  // }

  isEventCollectionInProgress() {
    return this.eventExtractionsStatusDto?.status == DealEventsCollectProcessStatus.InProgress;
  }
}
