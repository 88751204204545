import { Injectable, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
//import { EventBus } from "./event-bus";
import detectEthereumProvider from '@metamask/detect-provider';
import BigNumber from 'bignumber.js';

import IERC20Json from '../assets/abi/IERC20.json';
import ERC20BasicJson from '../assets/abi/ERC20Basic.json';
import DealJson from '../assets/abi/Deal.json';
import DealVestingJson from '../assets/abi/DealVesting.json';
import DealCollectWalletJson from '../assets/abi/DealCollectWallet.json';
import DealCollectWalletFactoryJson from '../assets/abi/DealCollectWalletFactory.json';
import StakingPoolJson from '../assets/abi/StakingPool.json';
import PoolFactoryJson from '../assets/abi/PoolFactory.json';
import StakingPenaltyPoolJson from '../assets/abi/StakingPenaltyPool.json';
import PancakeRouterJson from '../assets/abi/PancakeRouter.json';
import MerkleDistributorCreatorJson from '../assets/abi/MerkleDistributorCreator.json';
import MerkleDistributorJson from '../assets/abi/MerkleDistributor.json';
//import BLPDealJson from '../assets/abi/BLPDeal.json';
import DealFactoryJson from '../assets/abi/DealFactory.json';
import LockerJson from '../assets/abi/Locker.json';
import DealLockupsJson from '../assets/abi/DealLockups.json';

import { environment } from '../environments/environment';
import { EventBus } from './event-bus';
import { UserSessionProvider } from './user-session-provider';
import { error } from 'console';
import { NetworkNamePipe } from './pipes/networkName.pipe';
//import { UserSessionProvider } from "./user-session-provider";
import networks from './../app/networks.data';

declare const window: any;

export class ChainError extends Error {
  constructor(message: any) {
    super(message);
    this.name = 'ChainError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  public MetamaskName: string = 'metamask';
  public WalletconnectName: string = 'walletconnect';

  private readonly IERC20Abi: any;
  private readonly ERC20BasicAbi: any;
  public readonly DealAbi: any;
  public readonly DealFactoryAbi: any;
  public readonly DealVestingAbi: any;
  public readonly DealCollectWalletAbi: any;
  public readonly DealCollectWalletFactoryAbi: any;
  public readonly StakingPoolAbi: any;
  public readonly PoolFactoryAbi: any;
  public readonly StakingPenaltyPoolAbi: any;
  public readonly PancakeRouterAbi: any;
  public readonly MerkleDistributorCreatorAbi: any;
  public readonly MerkleDistributorAbi: any;
  private readonly LockerAbi: any;
  private readonly DealLockupsAbi: any;

  public web3: Web3 = new Web3();

  private walletConnectProvider: WalletConnectProvider =
    new WalletConnectProvider({
      rpc: {
        1: 'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        42: 'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        //BSC mainnet 56
        56: 'https://bsc-dataseed.binance.org/',
        //BSC testnet
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //Heco testnet
        256: 'https://http-testnet.hecochain.com',
      },
    });

  private ethereumProvider: any;

  public get chainIdNumber(): number {
    return this.userSessionProvider.getChainId();
  }

  public get CHTToken(): string {
    return environment.bsc.CHTToken;

    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.CHTToken;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.CHTToken;
    //}
    //throw new Error('Unsupported chain');
  }

  public get DealLockups(): string {
    return environment.bsc.dealLockups;
    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.dealLockups;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.dealLockups;
    //}
    //throw new Error('Unsupported chain');
  }

  public get DealFactory(): string {
    return environment.bsc.dealFactory;
    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.dealFactory;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.dealFactory;
    //}
    //throw new Error('Unsupported chain');
  }

  public get DealCollectWalletFactory(): string {
    return environment.bsc.dealCollectWalletFactory;
  }

  public get PoolFactory(): string {
    return environment.bsc.poolFactory;
  }
  public get merkleDistributorCreatorAddress(): string {
    // TODO: switch to typed parameters
    // ETH
    if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
      return environment.eth.merkleDistributorCreatorAddress;
    }
    // BSC
    else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
      return environment.bsc.merkleDistributorCreatorAddress;
    }
    // Polygon
    else if (this.chainIdNumber === 137 || this.chainIdNumber === 80001) {
      return environment.polygon.merkleDistributorCreatorAddress;
    }
    throw new Error('Unsupported chain');
  }

  public get pancakeRouterAddress(): string {
    return environment.bsc.pancakeRouterAddress;
  }

  public get Locker(): string {
    return environment.bsc.locker;

    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.locker;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.locker;
    //}
    //throw new Error('Unsupported chain');
  }

  constructor(
    private eventBus: EventBus,
    private userSessionProvider: UserSessionProvider
  ) {
    console.log('Web3Service constructor');
    this.IERC20Abi = IERC20Json.abi;
    this.ERC20BasicAbi = ERC20BasicJson.abi;
    this.DealAbi = DealJson;
    this.DealFactoryAbi = DealFactoryJson.abi;
    this.DealVestingAbi = DealVestingJson;
    this.DealCollectWalletAbi = DealCollectWalletJson;
    this.DealCollectWalletFactoryAbi = DealCollectWalletFactoryJson.abi;
    this.StakingPoolAbi = StakingPoolJson;
    this.PoolFactoryAbi = PoolFactoryJson.abi;
    this.StakingPenaltyPoolAbi = StakingPenaltyPoolJson;
    this.PancakeRouterAbi = PancakeRouterJson;
    this.MerkleDistributorCreatorAbi = MerkleDistributorCreatorJson;
    this.MerkleDistributorAbi = MerkleDistributorJson;
    this.LockerAbi = LockerJson.abi;
    this.DealLockupsAbi = DealLockupsJson.abi;
    //this.initWeb3();
  }

  async initWeb3() {
    console.log('initWeb3');
    this.ethereumProvider = await detectEthereumProvider({ timeout: 500 });
    if (this.ethereumProvider) {
      this.web3 = new Web3(this.ethereumProvider);

      var metamaskChainId = this.convertChainIdToHex(
        await this.web3.eth.getChainId()
      );
      //await window.ethereum.request({ method: 'eth_chainId' });
      console.log('matamask chainId: ' + metamaskChainId);
      if (parseInt(metamaskChainId, 16) != this.chainIdNumber) {
        this.setWeb3OnCustomRPC();
      }
      //TODO: that = this;
      //Reload when chain was changed in metamask (without connect wallet)
      var that = this;
      if (window.ethereum) {
        window.ethereum.on('chainChanged', function (chainId: string) {
          console.log(`chainChanged: ${chainId}`);
          let chainIdNumber = parseInt(chainId, 16);
          console.log('chainIdNumber: ' + chainIdNumber);
          if (chainIdNumber != that.chainIdNumber) {
            if (environment.supportedChains.indexOf(chainIdNumber) >= 0) {
              that.userSessionProvider.setChainId(chainIdNumber);
            } else {
              console.log('finishSession unsupported chain');
              that.userSessionProvider.finishSession();
            }
          }
          location.reload();
        });
      }
      return;
    } else {
      //this.isWeb3Disabled = true;
      if (!this.web3 || !this.web3.currentProvider) {
        this.setWeb3OnCustomRPC();
      }
    }

    //await this.updateChanId();

    //this.web3 = new Web3("https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b");
    //this.chainId = '0x2a';

    //this.chainId = '0x01';

    //await this.WalletConnect();
  }

  private setWeb3OnCustomRPC() {
    console.log(`set custom RPC for web3. ChainId: ${this.chainIdNumber}`);
    //ETH Mainnet
    if (this.chainIdNumber == 1) {
      this.web3 = new Web3(
        'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b'
      );
    }
    //Kovan
    else if (this.chainIdNumber == 42) {
      this.web3 = new Web3(
        'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b'
      );
    }
    //BSC
    else if (this.chainIdNumber == 56) {
      this.web3 = new Web3('https://bsc-dataseed.binance.org/');
    }
    //BSC Testnet
    else if (this.chainIdNumber == 97) {
      this.web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');
    }
    //Heco Testnet
    else if (this.chainIdNumber == 256) {
      this.web3 = new Web3('https://http-testnet.hecochain.com');
    }
  }

  //#region unlock wallet

  //async unlockWallet(): Promise<void> {
  public async unlockWalletconnect(reload = false): Promise<string> {
    var data: any = await this.WalletConnect();
    //this.account = data[0];
    this.userSessionProvider.linkWallet(data[0], this.WalletconnectName);
    this.eventBus.loginEvent.emit(data[0]);

    if (reload) {
      location.reload();
    }
    return data[0];
  }

  public async unlockMetamask(reload = false) {
    console.log('unlockMetamask');
    if (typeof window.ethereum == 'undefined') {
      //this.translate.get('MetaMask must be installed').subscribe((langResp: string) => {
      throw new ChainError('MetaMask must be installed');
      //});
      return false;
    }

    let chainId = await window.ethereum.request({ method: 'eth_chainId' });
    ////  Get Chain Id
    //TODO: check is this work in wallets
    //var walletChainIdNumber = await this.web3.eth.getChainId();

    let chainIdNumber = parseInt(chainId, 16);
    console.log('chainId: ' + chainId);
    console.log('chainIdNumber: ' + chainIdNumber);
    console.log('web3Service chainId: ' + this.chainIdNumber);

    if (this.chainIdNumber != chainIdNumber) {
      var toNetwork = networks.find((n) => n.chainId == this.chainIdNumber);
      if (toNetwork.networkParams) {
        try {
          // @ts-ignore
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: toNetwork.networkParams.chainId }],
          });
          return true;
        } catch (switchError: any) {
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [toNetwork.networkParams],
              });
            } catch (addError) {
              console.error(addError);
              this.userSessionProvider.finishSession();
            }
          }
        }
      } else {
        this.userSessionProvider.finishSession();
        throw new ChainError(
          `Select ${new NetworkNamePipe().transform(
            this.chainIdNumber
          )} Network in your wallet.`
        );
      }
    }

    // if (this.chainIdNumber != chainIdNumber) {
    //   //TODO: refactor
    //   //var toNetwork = networks.find(n => n.chainId == this.chainIdNumber);
    //   //if (toNetwork.networkParams) {
    //   //  try {
    //   //    // @ts-ignore
    //   //    await window.ethereum.request({
    //   //      method: 'wallet_addEthereumChain',
    //   //      params: [
    //   //        toNetwork.networkParams
    //   //      ],
    //   //    })
    //   //    return true
    //   //  } catch (error) {
    //   //    console.error(error)
    //   //    return false
    //   //  }
    //   //}
    //   this.userSessionProvider.finishSession();
    //   throw new ChainError(`Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`);
    //   return false;
    // }

    //if (environment.production) {
    //    if (chainId != '0x01' && chainId != '0x1' && chainId != '0x38') {
    //        this.showErrorModal(`Select Mainnet or BSC Network in MetaMask.`);
    //        //this.translate.get('select_right_metamask_network').subscribe((langResp: string) => {
    //        //    this.showErrorModal(langResp);
    //        //});
    //        return false;
    //    }
    //}
    //else {
    //    console.log(chainId);
    //    if (chainId != '0x2a' && chainId != '0x61') {
    //        this.showErrorModal(`Select Kovan or BSC Test Network in MetaMask.`);
    //        return false;
    //    }
    //}

    window.ethereum.enable().then(
      (data: any) => {
        console.log('enabled');
        console.log(data);

        if (data.length > 0) {
          //this.account = data[0];
          this.userSessionProvider.linkWallet(data[0], this.MetamaskName);
          this.eventBus.loginEvent.emit(data[0]);

          //TOOD: that = this;
          var that = this;
          if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts: any) {
              console.log('accountsChanged');
              console.log(accounts);
              location.reload();
            });
            //window.ethereum.on('disconnect', function (error: any) {
            //  console.log('disconnect');
            //  console.log(error);
            //  that.userSessionProvider.finishSession();
            //  location.reload();
            //})
            //window.ethereum.on('chainChanged', function (chainId: string) {
            //  console.log('chainChanged');
            //  console.log(chainId);
            //  if (chainId === "0x1")
            //    chainId = "0x01";
            //  if (chainId != that.chainId) {
            //    //if new chain is Ethereum
            //    if (chainId === '0x01' || chainId === '0x2a') {
            //      that.userSessionProvider.setETHNetwork();
            //    }
            //    //if new chain is BSC
            //    else if (chainId === '0x38' || chainId === '0x61') {
            //      that.userSessionProvider.setBSCNetwork();
            //    }
            //  }

            //  location.reload();
            //})
          }

          //TODO: remove reload, add eventBus
          if (reload) {
            location.reload();
          }
        }
      },
      (reason: any) => {
        console.log('My Permission to connect to Metamask was denied');
        console.log(reason);
        this.userSessionProvider.finishSession();
        location.reload();
      }
    );

    return true;
  }

  //#endregion

  async WalletConnect() {
    console.log('WalletConnect');
    //  Create WalletConnect Provider
    this.walletConnectProvider = new WalletConnectProvider({
      rpc: {
        1: 'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        42: 'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        //BSC mainnet 56
        56: 'https://bsc-dataseed.binance.org/',
        //BSC testnet
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //heco testnet
        256: 'https://http-testnet.hecochain.com',
      },
    });
    console.log(`chainIdNumber: ${this.chainIdNumber}`);
    this.walletConnectProvider.chainId = this.chainIdNumber;

    //  Enable session (triggers QR Code modal)
    var addresses = await this.walletConnectProvider.enable();
    console.log(addresses);

    //  Create Web3
    this.web3 = new Web3(this.walletConnectProvider as any);

    //  Get Chain Id
    var walletChainIdNumber = await this.web3.eth.getChainId();
    console.log('Wallet connect chainId: ' + walletChainIdNumber);
    if (this.chainIdNumber != walletChainIdNumber) {
      throw new ChainError(
        `Select ${new NetworkNamePipe().transform(
          this.chainIdNumber
        )} Network in your wallet.`
      );
      //this.userSessionProvider.finishSession();
    }

    // Subscribe to accounts change
    this.walletConnectProvider.on('accountsChanged', (accounts: string[]) => {
      console.log('accountsChanged ' + accounts);
      this.eventBus.accountsChanged.emit(accounts);
    });

    // Subscribe to chainId change
    this.walletConnectProvider.on('chainChanged', (chainId: number) => {
      console.log('chainChanged' + chainId);

      this.eventBus.chainChanged.emit(this.convertChainIdToHex(chainId));
    });

    // Subscribe to session connection
    this.walletConnectProvider.on('connect', () => {
      console.log('connect');
      this.eventBus.walletConnect.emit('');
    });

    // Subscribe to session disconnection
    this.walletConnectProvider.on(
      'disconnect',
      (code: number, reason: string) => {
        console.log(code, reason);
        this.eventBus.walletDisconnect.emit(reason);
      }
    );

    //console.log(this.web3);
    return addresses;
  }

  public convertChainIdToHex(value: number): string {
    var hexChainId = '0x' + value.toString(16);
    if (hexChainId === '0x1') hexChainId = '0x01';
    return hexChainId;
  }

  async WalletDisconnect() {
    if (this.walletConnectProvider) {
      // Close provider session
      await this.walletConnectProvider.disconnect();
    }
  }

  async PersonalSign(dataToSign: string, address: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.web3.eth.personal.sign(dataToSign, address, '', (error, resp) => {
        if (error) {
          console.log(error);
        }
        console.log(resp);
        resolve(resp);
      });
    }) as Promise<any>;
  }

  ////#region web3

  //#region ERC20BasicAbi
  async GetTransactionReceipt(tx: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.web3.eth.getTransactionReceipt(tx, (error, resp) => {
        console.log(resp);
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetDecimals(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.ERC20BasicAbi,
        contractAddress
      );
      contract.methods.decimals().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetTotalSupply(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.ERC20BasicAbi,
        contractAddress
      );
      contract.methods.totalSupply().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetAllowance(
    account: string,
    tokenForspend: string,
    forContractAddress: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.ERC20BasicAbi,
        tokenForspend
      );
      contract.methods
        .allowance(account, forContractAddress)
        .call({}, (error: any, resp: any) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  async GetTokenBalance(account: string, tokenAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.ERC20BasicAbi,
        tokenAddress
      );
      contract.methods.balanceOf(account).call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetContractSymbol(tokenAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.ERC20BasicAbi,
        tokenAddress
      );
      contract.methods.symbol().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetContractName(tokenAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.ERC20BasicAbi,
        tokenAddress
      );
      contract.methods.name().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  approve(account: string, tokenForspend: string, forContractAddress: string) {
    // new contract
    // DOC: https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#new-contract
    let contract = new this.web3.eth.Contract(this.IERC20Abi, tokenForspend);

    let contractEventSource = contract.methods
      .approve(
        forContractAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
      .send({ from: account });

    return contractEventSourceToObserves(contractEventSource);
  }

  async getEthBalance(customerAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.web3.eth.getBalance(customerAddress, (error, balance) => {
        resolve(balance);
      });
    }) as Promise<any>;
  }

  //#endregion ERC20BasicAbi

  //#region LockerAbi

  async getLockedTokenAmount(
    contractAddress: string,
    user: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.LockerAbi,
        contractAddress
      );
      contract.methods
        .getLockedBLP(user)
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<string>;
  }

  async getLockerWithdraw(
    contractAddress: string,
    _count: number
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.LockerAbi,
        contractAddress
      );
      contract.methods.withdraw(_count).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  lockerDeposit(account: string, tokenAmount: number, decimal: number) {
    let stringTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new this.web3.eth.Contract(this.LockerAbi, this.Locker);

    const contractEventSource = contract.methods
      .deposit(stringTokenAmount)
      .send({ from: account });

    return contractEventSourceToObserves(contractEventSource);
  }

  lockerWithdraw(account: string, depositCount: number) {
    const contract = new this.web3.eth.Contract(this.LockerAbi, this.Locker);

    const contractEventSource = contract.methods
      .withdraw(depositCount)
      .send({ from: account });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion LockerAbi

  //#region DealCreator

  // createDeal(
  // adminAddress: string,
  // paymentToken: string,
  // paymentDecimals: number,
  // tokenPrice: number,
  // rewardToken: string,
  // rewardDecimals: number,
  // startTimestamp: number,
  // finishTimestamp: number,
  // startClaimTimestamp: number,
  // minimumRaise: number,
  // maxDistributedTokenAmount: number,
  // allowRefund: boolean
  // ) {
  //   let masterContract = new this.web3.eth.Contract(
  //     this.DealFactoryAbi,
  //     this.DealFactory
  //   );

  //   let stringTokenPrice =
  //     '0x' + new BigNumber(tokenPrice).shiftedBy(paymentDecimals).toString(16);
  //   let stringStartDate = '0x' + new BigNumber(startTimestamp).toString(16);
  //   let stringFinishDate = '0x' + new BigNumber(finishTimestamp).toString(16);
  //   let stringStartClaimDate =
  //     '0x' + new BigNumber(startClaimTimestamp).toString(16);

  //   let stringMinimumRaise =
  //     '0x' +
  //     new BigNumber(minimumRaise).shiftedBy(paymentDecimals).toString(16);
  //   let stringMaxDistributedTokenAmount =
  //     '0x' +
  //     new BigNumber(maxDistributedTokenAmount)
  //       .shiftedBy(rewardDecimals)
  //       .toString(16);

  //   let stringRewardDecimals =
  //     '0x' + new BigNumber(rewardDecimals).toString(16);

  //   const contractEventSource = masterContract.methods
  //     .createDeal(
  //       [
  //         paymentToken,
  //         stringTokenPrice,
  //         rewardToken,
  //         stringStartDate,
  //         stringFinishDate,
  //         stringStartClaimDate,
  //         stringMinimumRaise,
  //         stringMaxDistributedTokenAmount,
  //         allowRefund,
  //       ],
  //       stringRewardDecimals
  //     )
  //     .send({ from: adminAddress });

  //   return contractEventSourceToObserves(contractEventSource);
  // }

  createDeal(
    adminAddress: string,
    paymentToken: string,
    paymentDecimals: number,
    tokenPrice: number,
    rewardToken: string,
    rewardDecimals: number,
    startTimestamp: number,
    finishTimestamp: number,
    startClaimTimestamp: number,
    minimumRaise: number,
    maxDistributedTokenAmount: number,
    allowRefund: boolean
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.DealFactoryAbi,
      this.DealFactory
    );

    let stringTokenPrice = new BigNumber(tokenPrice)
      .shiftedBy(paymentDecimals)
      .toFixed();
    let stringStartDate = new BigNumber(startTimestamp).toFixed();
    let stringFinishDate = new BigNumber(finishTimestamp).toFixed();
    let stringStartClaimDate = new BigNumber(startClaimTimestamp).toFixed();

    let stringMinimumRaise = new BigNumber(minimumRaise)
      .shiftedBy(paymentDecimals)
      .toFixed();
    let stringMaxDistributedTokenAmount = new BigNumber(
      maxDistributedTokenAmount
    )
      .shiftedBy(rewardDecimals)
      .toFixed();

    let stringRewardDecimals = new BigNumber(rewardDecimals).toFixed();

    const contractMethod = masterContract.methods.createDeal(
      [
        paymentToken,
        stringTokenPrice,
        rewardToken,
        stringStartDate,
        stringFinishDate,
        stringStartClaimDate,
        stringMinimumRaise,
        stringMaxDistributedTokenAmount,
        allowRefund,
      ],
      stringRewardDecimals
    );

    return contractMethod
      .estimateGas({ from: adminAddress })
      .then((estimatedGas: number) => {
        const gasLimit = estimatedGas + 20000;

        const contractEventSource = contractMethod.send({
          from: adminAddress,
          gas: gasLimit,
        });

        return contractEventSourceToObserves(contractEventSource);
      })
      .catch((error: any) => {
        console.error('Gas estimation failed:', error);
        throw error;
      });
  }

  // Commented by Ann (old version)
  //   createCollectWalletDeal(adminAddress: string, paymentToken: string, paymentDecimals: number, tokenPrice: number, startTimestamp: number, finishTimestamp: number, startClaimTimestamp: number,
  //     minimumRaise: number, maxDistributedTokenAmount: number,
  //     allowRefund: boolean) {

  //     let masterContract = new this.web3.eth.Contract(this.DealCollectWalletFactoryAbi, this.DealCollectWalletFactory);

  //     let stringTokenPrice = "0x" + new BigNumber(tokenPrice).shiftedBy(paymentDecimals).toString(16);
  //     let stringStartDate = "0x" + new BigNumber(startTimestamp).toString(16);
  //     let stringFinishDate = "0x" + new BigNumber(finishTimestamp).toString(16);
  //     let stringStartClaimDate = "0x" + new BigNumber(startClaimTimestamp).toString(16);

  //     let stringMinimumRaise = "0x" + new BigNumber(minimumRaise).shiftedBy(paymentDecimals).toString(16);
  //     let stringMaxDistributedTokenAmount = "0x" + new BigNumber(maxDistributedTokenAmount).shiftedBy(18).toString(16);

  //     // function createDeal(
  //     //    address _paymentToken,
  //     //    uint256 _tokenPrice,
  //     //    uint256 _startTimestamp,
  //     //    uint256 _finishTimestamp,
  //     //    uint256 _startClaimTimestamp,
  //     //    uint256 _minimumRaise,
  //     //    uint256 _maxDistributedTokenAmount,
  //     //    bool _allowRefund
  //     //)
  // console.log([paymentToken, stringTokenPrice, stringStartDate, stringFinishDate, stringStartClaimDate, stringMinimumRaise, stringMaxDistributedTokenAmount, allowRefund]);

  //     const contractEventSource = masterContract.methods
  //       .createDeal([paymentToken, stringTokenPrice, stringStartDate, stringFinishDate, stringStartClaimDate, stringMinimumRaise, stringMaxDistributedTokenAmount, allowRefund])
  //       .send({ from: adminAddress });

  //     return contractEventSourceToObserves(contractEventSource);
  //   }

  async createCollectWalletDeal(
    adminAddress: string,
    paymentToken: string,
    paymentDecimals: number,
    tokenPrice: number,
    startTimestamp: number,
    finishTimestamp: number,
    startClaimTimestamp: number,
    minimumRaise: number,
    maxDistributedTokenAmount: number,
    allowRefund: boolean
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.DealCollectWalletFactoryAbi,
      this.DealCollectWalletFactory
    );

    let stringTokenPrice = new BigNumber(tokenPrice)
      .shiftedBy(paymentDecimals)
      .toFixed();
    let stringStartDate = new BigNumber(startTimestamp).toFixed();
    let stringFinishDate = new BigNumber(finishTimestamp).toFixed();
    let stringStartClaimDate = new BigNumber(startClaimTimestamp).toFixed();

    let stringMinimumRaise = new BigNumber(minimumRaise)
      .shiftedBy(paymentDecimals)
      .toFixed();
    let stringMaxDistributedTokenAmount = new BigNumber(
      maxDistributedTokenAmount
    )
      .shiftedBy(18)
      .toFixed();

    try {
      const params = {
        paymentToken,
        tokenPrice: stringTokenPrice,
        rewardToken: '0x0000000000000000000000000000000000000000',
        startTimestamp: stringStartDate,
        finishTimestamp: stringFinishDate,
        startClaimTimestamp: stringStartClaimDate,
        minimumRaise: stringMinimumRaise,
        maxDistributedTokenAmount: stringMaxDistributedTokenAmount,
        allowRefund: allowRefund,
      };
      const gasEstimate = await masterContract.methods
        .createDeal(params)
        .estimateGas({ from: adminAddress });

      const contractEventSource = masterContract.methods
        .createDeal(params)
        .send({ from: adminAddress, gas: gasEstimate });

      return contractEventSourceToObserves(contractEventSource);
    } catch (error) {
      console.error('Gas estimation failed:', error);
      throw error;
    }
  }

  //#endregion DealCreator

  //#region DealLockups

  async getDealLockupsTiersLength(): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealLockupsAbi,
        this.DealLockups
      );
      contract.methods.getTiersLength().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealLockupsTiers(index: number): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealLockupsAbi,
        this.DealLockups
      );
      contract.methods.allTiers(index).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  dealLockupsAddTier(
    adminAddress: string,
    blpAmount: number,
    timeLockups: number
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.DealLockupsAbi,
      this.DealLockups
    );

    let stringBlpAmount =
      '0x' + new BigNumber(blpAmount).shiftedBy(18).toString(16);
    let stringTimeLockups = '0x' + new BigNumber(timeLockups).toString(16);

    //addTier(uint256 blpAmount, uint256 timeLockups)
    const contractEventSource = masterContract.methods
      .addTier(stringBlpAmount, stringTimeLockups)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealLockupsUpdateTier(
    adminAddress: string,
    index: number,
    blpAmount: number,
    timeLockups: number
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.DealLockupsAbi,
      this.DealLockups
    );

    let stringBlpAmount =
      '0x' + new BigNumber(blpAmount).shiftedBy(18).toString(16);
    let stringTimeLockups = '0x' + new BigNumber(timeLockups).toString(16);

    //updateTier(uint256 index, uint256 blpAmount, uint256 timeLockups)
    const contractEventSource = masterContract.methods
      .updateTier(index, stringBlpAmount, stringTimeLockups)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion DealLockups

  //#region PancakeRouterAbi
  async getAmountsOut(amountIn: number, path: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      let stringAmountIn = '0x' + new BigNumber(amountIn).toString(16);
      let contract = new this.web3.eth.Contract(
        this.PancakeRouterAbi,
        this.pancakeRouterAddress
      );
      contract.methods
        .getAmountsOut(stringAmountIn, path)
        .call({}, (error: any, resp: any) => {
          console.log(resp);
          console.log(error);
          resolve(resp);
        });
    }) as Promise<any>;
  }

  async WETH(): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.PancakeRouterAbi,
        this.pancakeRouterAddress
      );
      contract.methods.WETH().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  //#endregion PancakeRouterAbi

  //#region PoolFactoryAbi
  async GetStakeMasterFeeAmount(): Promise<number> {
    return new Promise((resolve, reject) => {
      console.log(this.PoolFactory);
      let contract = new this.web3.eth.Contract(
        this.PoolFactoryAbi,
        this.PoolFactory
      );
      contract.methods.feeAmount().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<number>;
  }

  async GetStakeMasterFeeToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.PoolFactoryAbi,
        this.PoolFactory
      );
      contract.methods.feeToken().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  createStakingPool(
    userAddress: string,
    stakingToken: string,
    poolToken: string,
    startTime: number,
    finishTime: number,
    poolTokenAmount: number,
    hasWhiteListing: boolean,
    depositFeeBP: number,
    feeTo: string,
    msgValue: number
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.PoolFactoryAbi,
      this.PoolFactory
    );
    console.log('createStakingPool');

    let stringStartTime = '0x' + new BigNumber(startTime).toString(16);
    let stringFinishTime = '0x' + new BigNumber(finishTime).toString(16);
    let stringPoolTokenAmount =
      '0x' + new BigNumber(poolTokenAmount).shiftedBy(18).toString(16);
    let stringDepositFeeBP = '0x' + new BigNumber(depositFeeBP).toString(16);

    //   function createStakingPool(
    //     IERC20 _stakingToken,
    //     IERC20 _poolToken,
    //     uint256 _startTime,
    //     uint256 _finishTime,
    //     uint256 _poolTokenAmount,
    //     bool _hasWhitelisting,
    //     uint256 _depositFeeBP,
    //     address _feeTo
    // )

    const contractEventSource = masterContract.methods
      .createStakingPool(
        stakingToken,
        poolToken,
        stringStartTime,
        stringFinishTime,
        stringPoolTokenAmount,
        hasWhiteListing,
        stringDepositFeeBP,
        feeTo
      )
      .send({ from: userAddress, value: msgValue });

    return contractEventSourceToObserves(contractEventSource);
  }

  // createStakingPenaltyPool(
  // userAddress: string,
  // stakingToken: string,
  // poolToken: string,
  // startTime: number,
  // finishTime: number,
  // poolTokenAmount: number,
  // hasWhiteListing: boolean,
  // depositFeeBP: number,
  // feeTo: string,
  // msgValue: number
  // ) {
  //   let masterContract = new this.web3.eth.Contract(
  //     this.PoolFactoryAbi,
  //     this.PoolFactory
  //   );

  //   let stringStartTime = new BigNumber(startTime).toFixed();
  //   let stringFinishTime =  new BigNumber(finishTime).toFixed();
  //   let stringPoolTokenAmount =
  //      new BigNumber(poolTokenAmount).shiftedBy(18).toFixed();
  //   let stringDepositFeeBP =  new BigNumber(depositFeeBP).toFixed();

  //   //   function createStakingPool(
  //   //     IERC20 _stakingToken,
  //   //     IERC20 _poolToken,
  //   //     uint256 _startTime,
  //   //     uint256 _finishTime,
  //   //     uint256 _poolTokenAmount,
  //   //     bool _hasWhitelisting,
  //   //     uint256 _depositFeeBP,
  //   //     address _feeTo
  //   // )

  //   const contractEventSource = masterContract.methods
  //     .createStakingPenaltyPool(
  //       stakingToken,
  //       poolToken,
  //       stringStartTime,
  //       stringFinishTime,
  //       stringPoolTokenAmount,
  //       hasWhiteListing,
  //       stringDepositFeeBP,
  //       feeTo
  //     )
  //     .send({ from: userAddress, value: msgValue });

  //   return contractEventSourceToObserves(contractEventSource);
  // }

  createStakingPenaltyPool(
    userAddress: string,
    stakingToken: string,
    poolToken: string,
    startTime: number,
    finishTime: number,
    poolTokenAmount: number,
    hasWhiteListing: boolean,
    depositFeeBP: number,
    feeTo: string,
    msgValue: number
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.PoolFactoryAbi,
      this.PoolFactory
    );

    let stringStartTime = new BigNumber(startTime).toFixed();
    let stringFinishTime = new BigNumber(finishTime).toFixed();
    let stringPoolTokenAmount = new BigNumber(poolTokenAmount)
      .shiftedBy(18)
      .toFixed();
    let stringDepositFeeBP = new BigNumber(depositFeeBP).toFixed();

    const method = masterContract.methods.createStakingPenaltyPool(
      stakingToken,
      poolToken,
      stringStartTime,
      stringFinishTime,
      stringPoolTokenAmount,
      hasWhiteListing,
      stringDepositFeeBP,
      feeTo
    );

    return method
      .estimateGas({ from: userAddress, value: msgValue })
      .then((estimatedGas: number) => {
        const gasLimit = estimatedGas + 20000;

        const contractEventSource = method.send({
          from: userAddress,
          value: msgValue,
          gas: gasLimit,
        });

        return contractEventSourceToObserves(contractEventSource);
      })
      .catch((error: any) => {
        console.error('Gas estimation failed:', error);
        throw error;
      });
  }

  //#endregion PoolFactoryAbi

  //#region StakingPoolAbi
  async rewardPerSec(address: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPoolAbi, address);
      contract.methods.rewardPerSec().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async allStakedAmount(address: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPoolAbi, address);
      contract.methods.allStakedAmount().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  depositToPool(
    userAddress: string,
    tokenAmount: number,
    tokenDecimals: number,
    poolAddress: string
  ) {
    let poolContract = new this.web3.eth.Contract(
      this.StakingPoolAbi,
      poolAddress
    );
    let bnTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
    const contractEventsSource = poolContract.methods
      .stakeTokens(bnTokenAmount)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventsSource);
  }

  withdrawFromPool(
    userAddress: string,
    tokenAmount: number,
    tokenDecimals: number,
    poolAddress: string
  ) {
    let poolContract = new this.web3.eth.Contract(
      this.StakingPoolAbi,
      poolAddress
    );
    let bnTokenAmount =
      '0x' + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
    const contractEventsSource = poolContract.methods
      .withdrawStake(bnTokenAmount)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventsSource);
  }

  async getUserInfo(userAddress: string, poolAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let poolContract = new this.web3.eth.Contract(
        this.StakingPoolAbi,
        poolAddress
      );
      poolContract.methods
        .getUserInfo(userAddress)
        .call({}, (error: any, resp: any) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  addAddressesToWhitelist(
    userAddress: string,
    poolAddress: string,
    addresses: string[]
  ) {
    let poolContract = new this.web3.eth.Contract(
      this.StakingPoolAbi,
      poolAddress
    );
    const contractEventsSource = poolContract.methods
      .add(addresses)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventsSource);
  }

  removeAddressesToWhitelist(
    userAddress: string,
    poolAddress: string,
    addresses: string[]
  ) {
    let poolContract = new this.web3.eth.Contract(
      this.StakingPoolAbi,
      poolAddress
    );
    const contractEventsSource = poolContract.methods
      .remove(addresses)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventsSource);
  }
  //#endregion StakingPoolAbi

  //#region DealAbi

  dealUpdateTime(
    dealAddress: string,
    adminAddress: string,
    startTimestamp: number,
    finishTimestamp: number,
    startClaimTimestamp: number
  ) {
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);
    let stringStartDate = '0x' + new BigNumber(startTimestamp).toString(16);
    let stringFinishDate = '0x' + new BigNumber(finishTimestamp).toString(16);
    let stringStartClaimDate =
      '0x' + new BigNumber(startClaimTimestamp).toString(16);

    const contractEventSource = masterContract.methods
      .setTime(stringStartDate, stringFinishDate, stringStartClaimDate)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealUpdateMaxDistributedTokenAmount(
    dealAddress: string,
    adminAddress: string,
    maxDistributedTokenAmount: string
  ) {
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);

    const contractEventSource = masterContract.methods
      .updateMaxDistributedTokenAmount(maxDistributedTokenAmount)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealCreateVestingContract(
    adminAddress: string,
    dealAddress: string,
    percent: number,
    start: number,
    interval: number,
    duration: number
  ) {
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);

    //let stringPercent = "0x" + new BigNumber(percent).toString(16);
    //createVestingContract(uint256 _percent,
    //uint256 _start,
    //uint256 _interval,
    //uint256 _duration)
    const contractEventSource = masterContract.methods
      .createVestingContract(percent, start, interval, duration)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealUpdateVestingPercent(
    adminAddress: string,
    dealAddress: string,
    percent: number
  ) {
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);

    //updateVestingPercent(uint256 _percent)
    const contractEventSource = masterContract.methods
      .updateVestingPercent(percent)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealAddTier(
    adminAddress: string,
    dealAddress: string,
    blpAmount: number,
    ticketSize: number,
    allocation: number,
    paymentDecimal: number,
    rewardDecimals: number
  ) {
    console.log('dealAddTier');
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);

    let stringBlpAmount =
      '0x' + new BigNumber(blpAmount).shiftedBy(18).toString(16);
    let stringTicketSize =
      '0x' + new BigNumber(ticketSize).shiftedBy(paymentDecimal).toString(16);
    let stringAllocation =
      '0x' + new BigNumber(allocation).shiftedBy(rewardDecimals).toString(16);

    //addTier(uint256 blpAmount, uint256 ticketSize, uint256 allocation)
    const contractEventSource = masterContract.methods
      .addTier(stringBlpAmount, stringTicketSize, stringAllocation)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealUpdateTier(
    adminAddress: string,
    dealAddress: string,
    index: number,
    blpAmount: number,
    ticketSize: number,
    allocation: number,
    paymentDecimal: number,
    rewardDecimals: number
  ) {
    console.log('dealUpdateTier');
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);

    let stringBlpAmount =
      '0x' + new BigNumber(blpAmount).shiftedBy(18).toString(16);
    let stringTicketSize =
      '0x' + new BigNumber(ticketSize).shiftedBy(paymentDecimal).toString(16);
    let stringAllocation =
      '0x' + new BigNumber(allocation).shiftedBy(rewardDecimals).toString(16);

    //updateTier(uint256 index, uint256 blpAmount, uint256 ticketSize, uint256 allocation)
    const contractEventSource = masterContract.methods
      .updateTier(index, stringBlpAmount, stringTicketSize, stringAllocation)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealWithdrawNotSoldTokens(adminAddress: string, dealAddress: string) {
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);

    ///// @dev Withdraw not sold tokens by owner
    ///// @param _emergency withdraw all tokens from contract.
    //function withdrawNotSoldTokens(bool _emergency) external onlyOwner
    const contractEventSource = masterContract.methods
      .withdrawNotSoldTokens(false)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealWithdrawFunds(adminAddress: string, dealAddress: string) {
    let masterContract = new this.web3.eth.Contract(this.DealAbi, dealAddress);

    ///// @dev Withdraw funds
    //function withdrawFunds() external onlyOwner nonReentrant allowClaimCondition
    const contractEventSource = masterContract.methods
      .withdrawFunds()
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  async getDealPaymentToken(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.paymentToken().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTokenPrice(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.tokenPrice().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealRewardToken(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.rewardToken().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealDecimals(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.decimals().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealStartTimestamp(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.startTimestamp().call({}, (error: any, resp: string) => {
        console.log(`startTimestamp: ${resp}`);
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealFinishTimestamp(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods
        .finishTimestamp()
        .call({}, (error: any, resp: string) => {
          console.log(`finishTimestamp: ${resp}`);
          resolve(resp);
        });
    }) as Promise<string>;
  }

  async getDealStartClaimTimestamp(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods
        .startClaimTimestamp()
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<string>;
  }

  async getDealMaxDistributedTokenAmount(
    contractAddress: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods
        .maxDistributedTokenAmount()
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<string>;
  }

  async getDealTotalRaise(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.totalRaise().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTokensForDistribution(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods
        .tokensForDistribution()
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<string>;
  }

  async getDealMinimumRaise(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.minimumRaise().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealDistributedTokens(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods
        .distributedTokens()
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<string>;
  }

  async getDealAllowRefund(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.allowRefund().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTiersLength(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.getTiersLength().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTiers(contractAddress: string, index: number): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.allTiers(index).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getVestingPercent(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.vestingPercent().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getDealVestingAddress(dealContractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealAbi,
        dealContractAddress
      );
      contract.methods.dealVesting().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getDealUserInfo(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods
        .userInfo(userAddress)
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  async getDealUsersTierIndex(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods
        .getTierIndex(userAddress)
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  dealPay(
    contractAddress: string,
    userAddress: string,
    amountWithDecimals: string,
    signature: string,
    payByETH: boolean
  ) {
    //let stringTokenAmount = "0x" + new BigNumber(amount).shiftedBy(decimals).toString(16);
    let wei = payByETH ? amountWithDecimals : 0;

    let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);

    //pay(uint256 _amount, bytes memory _signature) payable
    const contractEventSource = contract.methods
      .claim(amountWithDecimals, signature)
      .send({ value: wei, from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealClaim(contractAddress: string, userAddress: string) {
    let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);

    const contractEventSource = contract.methods
      .claim()
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion DealAbi

  //#region DealVesting

  async getVVestingStart(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealVestingAbi,
        contractAddress
      );
      contract.methods.vestingStart().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getVVestingInterval(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealVestingAbi,
        contractAddress
      );
      contract.methods
        .vestingInterval()
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  async getVVestingDuration(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealVestingAbi,
        contractAddress
      );
      contract.methods
        .vestingDuration()
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  async getVestingReleasableAmount(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealVestingAbi,
        contractAddress
      );
      contract.methods
        .releasableAmount(userAddress)
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  async getVestingForUser(
    contractAddress: string,
    userAddress: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.DealVestingAbi,
        contractAddress
      );
      contract.methods
        .vestings(userAddress)
        .call({}, (error: any, resp: string) => {
          resolve(resp);
        });
    }) as Promise<any>;
  }

  vestingRelease(contractAddress: string, userAddress: string) {
    let contract = new this.web3.eth.Contract(
      this.DealVestingAbi,
      contractAddress
    );

    const contractEventSource = contract.methods
      .release(userAddress)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  vestingUpdateVestingTime(
    adminAddress: string,
    vestingAddress: string,
    start: number,
    interval: number,
    duration: number
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.DealVestingAbi,
      vestingAddress
    );

    //setTime(uint256 _start, uint256 _interval, uint256 _duration)
    const contractEventSource = masterContract.methods
      .setTime(start, interval, duration)
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion DealVesting

  //#region MerkleDistributorCreatorAbi
  createMerkleDistributor(adminAddress: string, rewardToken: string) {
    let masterContract = new this.web3.eth.Contract(
      this.MerkleDistributorCreatorAbi,
      this.merkleDistributorCreatorAddress
    );

    //   function createDistributor(address _token, bytes32 _merkleRoot)

    const contractEventSource = masterContract.methods
      .createDistributor(
        rewardToken,
        '0x0000000000000000000000000000000000000000000000000000000000000000'
      )
      .send({ from: adminAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion MerkleDistributorCreatorAbi

  //#region MerkleDistributorAbi

  async getClaimingDealToken(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.MerkleDistributorAbi,
        contractAddress
      );
      contract.methods.token().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getClaimingDealMerkleRoot(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.MerkleDistributorAbi,
        contractAddress
      );
      contract.methods.merkleRoot().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }
  async isPausedClaimingDeal(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(
        this.MerkleDistributorAbi,
        contractAddress
      );
      contract.methods.paused().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  pauseClaimingDeal(adminAddress: string, contractAddress: string) {
    let masterContract = new this.web3.eth.Contract(
      this.MerkleDistributorAbi,
      contractAddress
    );
    const contractEventSource = masterContract.methods
      .pause()
      .send({ from: adminAddress });
    return contractEventSourceToObserves(contractEventSource);
  }

  unPauseClaimingDeal(adminAddress: string, contractAddress: string) {
    let masterContract = new this.web3.eth.Contract(
      this.MerkleDistributorAbi,
      contractAddress
    );
    const contractEventSource = masterContract.methods
      .unpause()
      .send({ from: adminAddress });
    return contractEventSourceToObserves(contractEventSource);
  }

  updateClaimingDealMerkleRoot(
    adminAddress: string,
    contractAddress: string,
    merkleRoot: string
  ) {
    let masterContract = new this.web3.eth.Contract(
      this.MerkleDistributorAbi,
      contractAddress
    );
    const contractEventSource = masterContract.methods
      .updateRoot(merkleRoot)
      .send({ from: adminAddress });
    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion MerkleDistributorAbi
  //#endregion web3
}

function contractEventSourceToObserves(contractEventSource: any) {
  const transactionHashSbj: Subject<string> = new Subject();
  const receiptSbj: Subject<any> = new Subject();
  const errorSbj: Subject<{
    error: any;
    receipt: any;
  }> = new Subject();

  contractEventSource
    .on('error', function (error: any, receipt: any) {
      console.log('contractEventSource error');
      console.log(error);
      errorSbj.error({ error, receipt });
      errorSbj.complete();
    })
    .on('transactionHash', function (hash: any) {
      transactionHashSbj.next(hash);
      transactionHashSbj.complete();
    })
    .on('receipt', function (receipt: any) {
      receiptSbj.next(receipt);
      receiptSbj.complete();
    });

  const transactionHash$ = transactionHashSbj
    .asObservable()
    .pipe(takeUntil(errorSbj));

  const receipt$ = receiptSbj.asObservable().pipe(takeUntil(errorSbj));

  const error$ = errorSbj.asObservable();

  return {
    transactionHash$,
    receipt$,
    error$,
  };
}
