import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "networkName"
})
export class NetworkNamePipe implements PipeTransform {
  transform(value: number): string {
    if (value == 1)
      return "ETH"
    else if (value == 42)
      return "Kovan"
    else if (value == 56)
      return "BSC"
    else if (value == 97)
      return "BSC Testnet"
    else if (value == 137)
      return "Polygon"
    else if (value == 80001)
      return "Amoy Testnet"
    else if (value == 80002)
      return "Heco Testnet"
    else if (value == 128)
      return "Heco"
    else if (value == -100)
      return "Solana"
    else if (value == -200)
      return "Tezos"
    else if (value == -300)
      return "Cardano"
    return value.toString();
  }
}
