<section class="__users-table">
  <table class="table table-responsive">
    <thead>
      <tr>
        <th class="__date-col">Registration</th>
        <th class="__email-col">
          <span matTooltip="Sort by e-mail" matTooltipPosition="above">
            E-mail
          </span>
        </th>
        <th class="__kys-col">KYC</th>
        <th class="__wallet-col">Wallet</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="__date-col td-responsive td-responsive-full" data-title="Registration">
          {{userDto?.creationDateTime}}
        </td>
        <td class="td-responsive td-responsive-full" data-title="E-mail">
          {{userDto?.email}}
        </td>
        <td class="td-responsive td-responsive-full" data-title="KYC">
          <span *ngIf="userDto?.kycConfirmed" class="__kys-status --passed">
            <i class="icomoon-checked"></i>
            Passed
          </span>
          <span *ngIf="!userDto?.kycConfirmed" class="__kys-status">
            <i class="icomoon-close-fill"></i>
            No
          </span>
        </td>
        <td class="td-responsive td-responsive-full" data-title="Wallet">
          <div class="__wallet">{{userDto?.ethAddress}}</div>
        </td>
      </tr>
    </tbody>
  </table>
</section>

<section class="__address-book-section dashboard-section mb-40">
  <div class="__address-book">
    <div class="__address-line" *ngFor="let item of allAddresses; index as i">
      <div class="__address-line__status">
        <span class="__status icomoon-checked" *ngIf="item.emissionAddress"></span>
        <span class="__status icomoon-info text-error"
              matTooltip="For participating deals which will distribute tokens to {{item.networkId | networkName}} wallet you need to add wallet address"
              matTooltipPosition="above" *ngIf="!item.emissionAddress">
        </span>
      </div>
      <div class="__address-line__network">
        <img [src]="item.networkId | imgNetworkPipe" alt="" class="logo-24">
      </div>
      <div class="__address-line__wallet">
        <div class="caption text-muted mb-1">{{item.networkId | networkName}}</div>
        <div class="__wallet small-text">{{item.emissionAddress}}</div>
      </div>
    </div>
  </div>
</section>

<section class="__history dashboard-section">
  <div class="card">
    <div class="card-header pb-1 border-none">
      <div class="subtitle-1">Wallet History</div>
    </div>
    <div class="card-body">
      <div class="__address-book">
        <div class="__address-line" *ngFor="let item of dbAddressChangeRequests; index as i">
          <div class="__address-line__network">
            <img [src]="item.networkId | imgNetworkPipe" alt="" class="logo-24">
          </div>
          <div class="__address-line__wallet">
            <div class="caption text-muted mb-1">{{item.networkId | networkName}}</div>
            <div class="__wallet small-text" matTooltip="{{item.updatedTimeStamp* 1000 | date:'dd MMM, yyyy H:mm a'}}">{{item.emissionAddress}}</div>
          </div>
          <div class="__address-line__actions">
            <div class="__history-status text-warning" *ngIf="item.status == enumStatus.Pending">
              <i class="icon icomoon-staking"></i>
              Pending
            </div>
            <button mat-stroked-button
                    class="btn-icon btn-icon-left" *ngIf="item.status == enumStatus.Pending"
                    (click)="declineAddressBookChangeRequest(item)">
              <i class="icon icomoon-close"></i>
              Decline
            </button>
            <button mat-stroked-button
                    class="btn-icon btn-icon-left ms-2"
                    *ngIf="item.status == enumStatus.Pending"
                    (click)="approveAddressBookChangeRequest(item)">
              <i class="icon icomoon-checkmark"></i>
              Approve
            </button>
            <div class="__history-status text-muted"
                 *ngIf="item.status == enumStatus.Declined">
              <i class="icon icomoon-close"></i>
              Decline
            </div>
            <div class="__history-status text-success" *ngIf="item.status == enumStatus.Accepted">
              <i class="icon icomoon-checkmark"></i>
              Approved
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

