<main class="dashboard-main">
  <!--<info-join-deals></info-join-deals>-->
  <section class="dashboard-section __edit-deal-link dashboard-white">
    <button mat-raised-button
            color="primary"
            class="w-100 btn-icon btn-icon-left"
            (click)="navigateToEditDeal()">
      <i class="icon icomoon-edit"></i>
      Edit Deal
    </button>
  </section>

  <section class="dashboard-section __deal-main-info pt-40">
    <div class="card">
      <div class="card-header d-flex flex-wrap align-items-center">
        <div class="token-name token-name-inline">
          <div class="token-name__img">
            <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" alt=""/>
          </div>
          <div class="token-name__info">
            <div class="token-title">{{deal?.name}}</div>
            <div class="token-symbol">{{deal?.rewardTokenSymbol}}</div>
          </div>
        </div>

        <button *ngIf="rewardToken" class="address-btn --copy">
          <span [cdkCopyToClipboard]="rewardToken">{{rewardToken}}</span>
        </button>

        <div class="d-lg-flex align-items-center ms-lg-auto __deal-header-link">
          <a [href]="rewardToken | blockExplorerAddressPipe: deal?.chainId"
             target="_blank"
             mat-stroked-button
             class="__bscscan-link"
             *ngIf="!isFinished">
            View BSC Scan
          </a>

          <button (click)="scrollToDescription()" mat-stroked-button class="__about-link ms-lg-2 btn-icon btn-icon-left">
            <i class="icon icomoon-arrow-down"></i>
            About Project
          </button>
        </div>

      </div>
      <div class="card-content">
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 order-1">
            <div class="d-flex align-items-center">
              <button
                *ngIf="!isFinished"
                (click)="addToGoogleCalendar()"
                mat-icon-button
                class="__add-to-google me-3"
                matTooltip="Add to Google Calendar"
                matTooltipPosition="above">
                <img src="../../assets/images/google-calendar.svg" alt="">
              </button>

              <div>
                <!-- Upcoming & Progress -->
                <div *ngIf="isUpcoming || isStarted">
                  <div class="subtitle-1 mb-1" *ngIf="isUpcoming">Starts in</div>
                  <span class="subtitle-1 mb-1" *ngIf="now > startTimestamp">Closes in</span>

                  <div class="countdown h6 mb-1">
                    <span *ngIf="timerViewDays">{{timerViewDays}}d</span>
                    <span *ngIf="timerViewHours"> {{timerViewHours}}h</span>
                    <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}m</span>
                    <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}s</span>
                  </div>
                </div>

                <!-- Finished -->
                <div class="subtitle-1 mb-1" *ngIf="isFinished">Deal Status</div>
                <div class="h6 mb-1" *ngIf="isFinished">Finished</div>

                <div class="small-text text-muted">{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 mb-4 mb-md-0 order-2 order-md-3 order-lg-2">
            <div class="subtitle-1 mb-1">Tokens Purchased</div>
            <div class="token-name token-name-sm mb-1">
              <div class="token-name__img">
                <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" alt=""/>
              </div>
              <div class="token-name__info">
                <div class="token-title">{{tokensForDistribution | shiftDecimals:-rewardDecimals| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}</div>
              </div>
            </div>
            <div class="small-text text-muted">{{totalRaise| shiftDecimals:-paymentDecimal| number: '1.0-8'}} {{paymentTokenSymbol}}</div>
          </div>

          <div class="col-md-6 col-lg-3 mb-4 mb-md-0 order-3 order-md-4 order-lg-3">
            <div class="subtitle-1 mb-1">Total Raise</div>
            <div class="token-name token-name-sm mb-1">
              <div class="token-name__img">
                <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" alt=""/>
              </div>
              <div class="token-name__info">
                <div class="token-title">
                  {{maxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}
                </div>
              </div>
            </div>
            <span class="small-text text-muted"
                  matTooltip="1 {{paymentTokenSymbol}} = {{tokensPerETH| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}"
                  matTooltipPosition="above">
              {{getTotalRaiseETH(deal)| number: '1.0-2'}} {{deal?.paymentTokenSymbol}}
            </span>
          </div>
          <div class="col-md-6 col-lg-3 mb-md-4 mb-lg-0 order-4 order-md-2 order-lg-4">
            <div class="subtitle-1 mb-1">Deal Progress</div>

            <div class="__deal-progress">
              <div class="caption text-right text-muted mb-1">{{getDistributedLeftAmount | number: '1.0-2'}} Left</div>
              <div class="progress progress-xs">
                <div class="progress-bar" role="progressbar" [style.width.%]="getDistributedPercent"></div>
              </div>
              <div class="progress-legend d-flex justify-content-between">
                <div class="progress-legend__percent">{{getDistributedPercent| number: '1.0-2'}}%</div>
                <div class="progress-legend__value">{{tokensForDistribution | shiftDecimals:-rewardDecimals| number: '1.0-2'}}/{{maxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-section __deal-general-info pt-40">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-lg mb-3 mb-lg-0" *ngIf="deal?.emissionDescription">
            <div class="caption text-muted mb-1">Vesting</div>
            <div class="small-text">{{deal?.emissionDescription}}</div>
          </div>
          <div class="col-lg">
            <div class="caption text-muted mb-1">Deal Price</div>
            <div class="small-text">1 {{paymentTokenSymbol}} = {{tokensPerETH| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}</div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="row">
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">Target Network</div>
            <div class="network-name">
              <img [src]="deal?.targetNetwork | imgNetworkPipe" class="network-name__img" alt=""/>
              <span class="network-name__title small-text">{{deal?.targetNetwork|networkName}}</span>
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">Deposit</div>
            <div class="network-name">
              <img [src]="deal?.paymentTokenSymbol | imgSymbol" class="network-name__img" alt=""/>
              <span class="network-name__title small-text">{{deal?.paymentTokenSymbol}}</span>
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">Type</div>
            <div class="small-text"><div class="subtitle-1">{{deal?.dealType | dealTypeName}}</div></div>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0">
            <div class="caption text-muted mb-1">Start</div>
            <div class="small-text">{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0">
            <div class="caption text-muted mb-1">End</div>
            <div class="small-text">{{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0">
            <div class="caption text-muted mb-1">Token Generation</div>
            <div class="small-text">{{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-section __deal-tiers-info pt-40">
    <div class="card">
      <div class="card-header">Ticket Size and Allocation</div>
      <div class="card-content pb-0">
        <div class="deal-tiers">
          <div class="tier-group" *ngFor="let item of allTiers; index as i;">
            <div class="tier-group__title caption text-muted mb-1">{{i | nameTierPipe}}</div>
            <div class="tier-group__progress d-md-flex align-items-center">
              <div class="col-auto mb-2 mb-md-0 me-md-4 d-flex align-items-center">
                <img [src]="i | imgTierPipe" alt="" class="tier-group-logo"/>
                <img [src]="deal?.chainId | imgNetworkPipe" class="tier-group-chain" alt=""/>
                <div class="small-text">
                  {{item.ticketSize| shiftDecimals:-rewardDecimals}} {{paymentTokenSymbol}}
                </div>
              </div>
              <div class="col">
                <div class="progress progress-xs">
                  <div class="progress-bar"
                       role="progressbar"
                       [style.width.%]="getDistributedPercentTier(item.allocation,item.purchasedTokens)">
                  </div>
                </div>
                <div class="progress-legend d-flex justify-content-between">
                  <div class="progress-legend__percent">
                    {{getDistributedPercentTier(item.allocation,item.purchasedTokens) | number: '1.0-2'}}%
                  </div>
                  <div class="progress-legend__value">
                    {{item.purchasedTokens| shiftDecimals:-rewardDecimals| number: '1.0-2'}} /
                    {{item.allocation| shiftDecimals:-rewardDecimals| number: '1.0-2'}}
                  </div>
                  <div class="progress-legend__img" *ngIf="deal?.logoURL"><img [src]="deal?.logoURL" alt=""/></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-section __deal-description-info p-40" id="deal-about">
    <div class="card">
      <div class="card-header">About The Project</div>
      <div class="card-content">
        <div class="row">
          <div class="col-lg-7">
            <p>{{deal?.description}}</p>
          </div>
          <div class="col-lg-5 mb-3 mb-lg-0">
            <div class="social-list justify-content-lg-end">
              <a *ngIf="deal?.twitterURL" [href]="deal?.twitterURL" target="_blank">
                <img src="../../assets/images/social/twitter.svg" alt="">
              </a>
              <a *ngIf="deal?.mediumURL" [href]="deal?.mediumURL" target="_blank">
                <img src="../../assets/images/social/medium.svg" alt="">
              </a>
              <a *ngIf="deal?.youTubeURL" [href]="deal?.youTubeURL" target="_blank">
                <img src="../../assets/images/social/youtube.svg" alt="">
              </a>
              <a *ngIf="deal?.facebookURL" [href]="deal?.facebookURL" target="_blank">
                <img src="../../assets/images/social/facebook.svg" alt="">
              </a>
              <a *ngIf="deal?.telegramURL" [href]="deal?.telegramURL" target="_blank">
                <img src="../../assets/images/social/telegram.svg" alt="">
              </a>
              <a *ngIf="deal?.linkedInURL" [href]="deal?.linkedInURL" target="_blank">
                <img src="../../assets/images/social/linkedIn.svg" alt="">
              </a>
              <a *ngIf="deal?.instagramURL" [href]="deal?.instagramURL" target="_blank">
                <img src="../../assets/images/social/instagram.svg" alt="">
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-7">
            <a [href]="deal?.url" *ngIf="deal?.url" mat-button color="primary" class="btn-icon btn-icon-left icon-link" target="_blank">
              <i class="icon icomoon-list"></i>
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
