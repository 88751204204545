export const environment = {
  production: false,
  polygon: {
    merkleDistributorCreatorAddress: '0x0000000000000000000000000000000000000000',
  },
  eth: {
    merkleDistributorCreatorAddress: '0x0000000000000000000000000000000000000000'
  },
  bsc: {
    merkleDistributorCreatorAddress: '0x0000000000000000000000000000000000000000',
    CHTToken: '0x8Fe1E6EC1fe149A525008FD4f81009Aec7bf787a', //App#CHTToken
    dealFactory: '0x5B3b939Ea3a5Dda4A4aa07B70E62Aef29188Bc7B', //App#DealFactory
    dealCollectWalletFactory: '0x37bb7e930a34575415261D0994EB507cbAEAF5D0', //App#DealCollectWalletFactory
    locker: '0xd915f1cc1Cbe1131740c279085E1214538987773', //App#Locker
    dealLockups: '0x20A01900D77d914a8288baB06647E189236dEf22', //App#DealLockups
    poolFactory: '0xdB388D47B7F45e75987c1F505e9068f60200f054', //App#PoolFactory
    pancakeRouterAddress: '0x354bc1cFe9959b96beD409859a726263C6C56C81', //not changed address
    paymentTokens: [
      { name: "BusdToken", address: "0x786af96A4F83ccc7a972B8cc70D769Aca5Fb8cBF", icon: 'busd.svg'},
      { name: "ChTest1Token", address: "0xCE680d8756BFeFc0C86236A7F0ad8e012c04265F", icon: 'busd.svg'},
      { name: "ChTest2Token", address: "0x3926F8Ed2F14ff84606dA7bd796312e0a10C3fb3", icon: 'busd.svg'},
      { name: "ChTest3Token", address: "0x6ba3fEA9bddccCBf536d31B549756c4ee4CBB180", icon: 'busd.svg'},
      { name: "ChTest4Token", address: "0x611C3C4D1D2BDF78E43aEF8530d04d2021baB070", icon: 'busd.svg'},
      { name: "ChTest5Token", address: "0x754C426aD715B125E1f76e0cc0aE50D501f31A55", icon: 'busd.svg'},
      { name: "ChTest6Token", address: "0x820FbB1fF151EBe6A56cC1Ece7812DDf363EF0C6", icon: 'busd.svg'},
      { name: "ChTest7Token", address: "0x665dF3Bf5e7FC6A1733AcEf3a09F9fAa2Ce51eC5", icon: 'busd.svg'},
      { name: "ChTest8Token", address: "0xA9866cF0B6331E4D76bc89FeE142a7611F01BC95", icon: 'busd.svg'},
      { name: "TusdToken", address: "0xE39C8409aEBaECe4Bd01cF00674e30eD72d672F1", icon: 'busd.svg'},
      { name: "UsdcToken", address: "0xE315Dbab09C36D4F15a7AF88F18F937507c164CC", icon: 'busd.svg'},
    ],
    networkTypes: [
      { id: 1, label: 'ETH', env:'prod' },
      { id: 56, label: 'BSC', env:'prod' },
      { id: 137, label: 'Polygon', env:'prod' },
      { id: -100, label: 'Solana', env:'prod' },
      { id: 97, label: 'BSC Testnet', env:'dev' },
      { id: 42, label: 'Kovan', env:'dev' },
      { id: 80002, label: 'Amoy Testnet', env:'dev' }
    ]
  },
  remoteServiceBaseUrl: "https://devadmin.chateau.capital",
  //To generate direct link to view users profile
  //https://test-api.sumsub.com/checkus#/applicant/60bce8ed8a9a0400018c7f74/basicInfo?clientId=bullperks
  sumsupApplicantUrl: 'https://api.sumsub.com/checkus#/applicant/',
  sumsupClientId: 'bullperks',
  defaultChainId: 80002,
  supportedChains: [1, 56, 137, 97, 42, 80002],
};
