<main class="dashboard-main">
  <section class="create-pool-section">
    <mat-tab-group
      mat-align-tabs="start"
      class="fullpage-tabs"
      disableRipple>
      <mat-tab label="General">
        <div class="p-40">
          <div class="row mb-40">
            <div class="col-lg-6 mb-4 mb-lg-0">
              <div class="card p-4">
                <div class="d-flex align-items-center mb-4">
                  <i class="icon icomoon-wallet me-2 text-muted"></i>
                  <div class="subtitle-1">My Balance</div>
                </div>

                <div class="d-flex align-items-center">
                  <img src="../../assets/images/tokens/blp.svg" alt="" class="image-24 me-2">
                  <div class="h5">0 BLP</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card p-4">
                <div class="subtitle-1 mb-4">Create Pool Fee Amount</div>

                <div class="d-flex align-items-center">
                  <img src="../../assets/images/tokens/blp.svg" alt="" class="image-24 me-2">
                  <div class="h5">{{feeAmount}} BLP</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-20">
            <div class="col-12 mb-20">
              <div class="card p-4">
                <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                  <mat-label>Deposit Fee</mat-label>
                  <input matInput
                         type="text"
                         [(ngModel)]="depositFeeBP"
                         name="depositFeeBP"
                         disabled
                  >
                </mat-form-field>

                <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                  <mat-label>Staking Token</mat-label>
                  <input matInput
                         type="text"
                         [(ngModel)]="stakingToken"
                         name="stakingToken"
                         disabled
                  >
                </mat-form-field>

                <mat-form-field appearance="fill" hideRequiredMarker class="mb-12">
                  <mat-label>Reward Token</mat-label>
                  <input matInput
                         type="text"
                         [(ngModel)]="poolToken"
                         name="poolToken"
                         disabled
                  >
                </mat-form-field>

                <mat-form-field appearance="fill" hideRequiredMarker>
                  <mat-label>Reward Token Supply</mat-label>
                  <input matInput
                         type="text"
                         [(ngModel)]="poolTokenAmount"
                         name="poolTokenAmount"
                         disabled
                  >
                </mat-form-field>

                <!-- <div class="col-lg-6">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="pool-token-per-block" placeholder="Tokens per Block" />
                      <label for="pool-token-per-block">Tokens per Block</label>
                    </div>
                  </div> -->

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-3 mb-lg-0">
              <div class="card p-4">
                <div class="subtitle-1 text-break mb-3">Start Date (UTC)</div>
                <div class="small-text mb-3">
                  {{startTime * 1000 | date:'dd MMM, yyyy H:mm a'}}
                </div>

                <mat-form-field appearance="fill">
                  <mat-label>Start Timestamp</mat-label>
                  <input matInput
                         type="number"
                         [(ngModel)]="startTime"
                         name="startTime"
                         disabled
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card p-4">
                <div class="subtitle-1 text-break mb-3">End Date (UTC)</div>
                <div class="small-text mb-3">
                  {{finishTime * 1000 | date:'dd MMM, yyyy H:mm a'}}
                </div>

                <mat-form-field appearance="fill">
                  <mat-label>End Timestamp</mat-label>
                  <input matInput
                         type="number"
                         [(ngModel)]="finishTime"
                         name="finishTime"
                         disabled
                  >
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Appearance">
        <div class="__min-height">
          <div class="p-40">
            <div class="row mb-40">
              <div class="col-lg-6">
                <div class="card p-4 mb-4 mb-lg-0">
                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-3">
                    <mat-label>Name</mat-label>
                    <input matInput
                           type="text"
                           [(ngModel)]="poolName"
                           name="poolName"
                           required
                    >
                  </mat-form-field>

                  <mat-form-field appearance="fill" hideRequiredMarker class="mb-3">
                    <mat-label>Description</mat-label>
                    <textarea matInput
                              rows="6"
                              [(ngModel)]="poolDescription"
                              name="poolDescription"
                              required>
                    </textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="__type card p-4 d-flex align-items-center mb-4">
                  <div class="subtitle-1 me-auto">Type</div>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input"
                           type="radio"
                           name="pool_type"
                           id="pool-type-1"
                           value="1"
                           [checked]="isApproved"
                           (change)="onApprovedChanged(true)"
                    />
                    <label class="form-check-label" for="pool-type-1">Approved</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input"
                           type="radio"
                           name="pool_type"
                           id="pool-type-2"
                           value="0"
                           [checked]="!isApproved"
                           (change)="onApprovedChanged(false)"
                    />
                    <label class="form-check-label" for="pool-type-2">Public</label>
                  </div>
                </div>


                <div class="__is-visible card p-4 d-flex align-items-center">
                  <div class="subtitle-1 me-auto">Visible for Users</div>
                  <mat-slide-toggle
                    name="visibleForUsers"
                    [(ngModel)]="visibleForUsers"
                  >
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <div class="form-save mt-auto bg-white">
            <button type="submit"
                    class="w-100"
                    mat-raised-button
                    color="primary"
                    (click)="updateStakingPoolDb()">
              Save Changes
            </button>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Whitelist">
        <div class="__min-height">
          <div class="p-40">
            <div class="card p-4 mb-40">
              <div class="d-flex align-items-center">
                <div class="subtitle-1 me-auto">Whitelisting</div>
                <mat-slide-toggle
                  name="hasWhiteListing"
                  [(ngModel)]="hasWhiteListing"
                >
                </mat-slide-toggle>
              </div>
            </div>

            <div class="card p-4 mb-40">
              <div class="subtitle-1 mb-4">Whitelist</div>

              <mat-form-field appearance="fill">
                <mat-label>Whitelisted Addresses</mat-label>
                <textarea matInput [(ngModel)]="stringWhitelist"
                          name="stringWhitelist"
                          rows="15"
                          [disabled]="!hasWhiteListing">
                ></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="form-save mt-auto bg-white">
            <button type="submit"
                    class="w-100"
                    mat-raised-button
                    color="primary"
                    (click)="updateWhitelistClick()">
              Save Whitelist
            </button>
          </div>
        </div>
      </mat-tab>

      <!-- <mat-tab label="Withdraw">
        <div class="p-40">
          <div class="row">
            <div class="col-lg-6">
              <div class="card p-4">
                <button type="submit"
                        class="w-100 mb-12"
                        mat-raised-button
                        color="primary">
                  Withdraw funds
                </button>
                <button type="submit"
                        class="w-100"
                        mat-raised-button
                        color="primary">
                  Withdraw not sold tokens
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab> -->
    </mat-tab-group>
  </section>
</main>
