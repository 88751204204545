import { environment } from "src/environments/environment";

export interface MenuItem {
  readonly label: string,
  readonly routePath: string[] | string,
  readonly supportedChains: number[],
  readonly icon?: string;
  readonly childItems?: MenuItem[],
  readonly isExternal?: boolean;
  readonly disabled?: boolean;
}

export const menu: MenuItem[] = [
  {
    icon: "icomoon-users",
    label: "Users",
    routePath: "/users",
    supportedChains: [56, 97, 80002],
  },
  {
    icon: "icomoon-users",
    label: "Admins",
    routePath: "/admins",
    supportedChains: [56, 97, 80002],
  },
  {
    icon: "icomoon-address-book",
    label: "Wallet Approvals",
    routePath: ["/wallet-approval"],
    supportedChains: [56, 97, 80002],
  },
  {
    icon: "icomoon-chart-down",
    label: "Penalties",
    routePath: "/penalties",
    disabled: true,
    supportedChains: [56, 97, 80002],
  },
  {
    label: "Tiers",
    icon: "icomoon-tiers",
    routePath: ["/tiers"],
    supportedChains: [56, 97, 80002],
  },
  {
    label: "Deals",
    icon: "icomoon-claim",
    routePath: ["/deals", `/create-deal`, `/create-wallet-deal`, "/deal-detail"],
    supportedChains: [56, 97, 80002],
    childItems: [{
      label: "Deals",
      routePath: `/deals`,
      supportedChains: [56, 97, 80002],
    },
    {
      label: "Create Deal",
      routePath: `/create-deal`,
      supportedChains: [56, 97, 80002],
    },
    {
      label: "Create empty Deal",
      routePath: `/create-wallet-deal`,
      supportedChains: [56, 97, 80002],
    },]
  },
  // {
  //   label: "Claiming Deals",
  //   icon: "icomoon-claim",
  //   routePath: "/claiming",
  //   supportedChains: [],
  // },
  // {
  //   label: "Create Claiming Deal",
  //   icon: "icomoon-claim",
  //   routePath: "/create-claiming-deal",
  //   supportedChains: [],
  // },
  {
    label: "Pools",
    icon: "icomoon-pools",
    routePath: ["/staking", `/farming`, `/create-pool`, `/pool-detail`, "/edit-pool"],
    supportedChains: [56, 97, 80002],
    childItems: [{
      label: "Staking Pools",
      routePath: `/staking`,
      supportedChains: [56, 97, 80002],
    },
    {
      label: "Farming Pools",
      routePath: `/farming`,
      supportedChains: [56, 97, 80002],
    },
    {
      label: "Create Pool",
      routePath: `/create-pool`,
      supportedChains: [56, 97, 80002],
    },]
  },
  {
    label: "Settings",
    icon: "icomoon-warning",
    routePath: "/settings",
    supportedChains: [56, 97, 80002],
    disabled: true
  },
];
